import { SweetAlertOptions, SweetAlertResult } from "sweetalert2";
import { Swal } from "..";

/**
 * Renderiza um swal de warning
 * @param infoMsg Mensagem
 * @param infoTitle Título 
 */
function InfoSwal(infoMsg?: string, infoTitle?: string, handleOption?: (option: SweetAlertResult<any>) => void, customOptions: SweetAlertOptions = {}) {
  Swal({
    showConfirmButton: true,
    ...customOptions,
    title: infoTitle || 'Atenção',
    text: infoMsg,
    icon: 'warning'
  }).then(opt => handleOption?.(opt));
}

export default InfoSwal;