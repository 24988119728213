import { ComponentType } from 'react';

// Hooks
import useLayout from '../hooks/use-layout';
import { useNavigate } from 'react-router-dom';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';

// Utils
import Authentication from 'root-resources/oauth/authentication';
import FilialAPI from 'root-resources/api/cadastros/filial/filial';
import { ApiErrorSwal } from 'root-components';

// Models
import { AxiosResponse } from 'axios';
import { UsuarioEmpresaLogada } from 'root-models/usuario-empresa-logada';
import Filial from 'root-models/cadastros/filial/filial';
import StyledSwal from 'root-components/swal';

const oauth = new Authentication();
const filialApi = new FilialAPI();

/**
 * High Order Component para controlar autenticação do usuário
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
function withAuth(WrappedComponent: ComponentType<any>) {
  return () => {
    const navigate = useNavigate();
    const layout = useLayout();

    useComponentDidMount(handleUserToken);

    function handleUserToken() {
      const token = Authentication.getToken();

      if (!token) redirectLogin();
      else if (oauth.userTokenHasExpired() || !layout.reducer.company) refreshToken();
    }

    function refreshToken() {
      layout.actions.setLoading(true);
      layout.actions.setLoadingCompanyData(true);
      oauth.refreshToken().then(findUserCompany).catch(redirectLogin);
    }

    function findUserCompany() {
      oauth
        .findUsuarioEmpresaLogado()
        .then((result) => {
          if (!result.data.empresa) navigate('/alterar-empresa');

          updateUserCompanyData(result);
        })
        .catch(redirectLogin);
    }

    function updateUserCompanyData(result: AxiosResponse<UsuarioEmpresaLogada, any>) {
      layout.actions.setCompany(result.data.empresa);
      layout.actions.setUserLogin(result.data.usuario);
      getUserCompanyBranchData();
    }

    function getUserCompanyBranchData() {
      layout.actions.setLoading(true);
      layout.actions.setLoadingCompanyData(true);

      filialApi
        .findByPage({ page: 1, orderField: 'nmFantasia', orderType: 'ASC', staticParams: ',stEmpresa:1' })
        .then((res) => updateFilialGlobalState(res.data.data))
        .catch((err) => ApiErrorSwal(err, 'Falha ao carregar filiais', 'Erro ao buscar dados'))
        .finally(() => {
          layout.actions.setLoadingCompanyData(false);
          layout.actions.setLoading(false);
        });
    }

    function updateFilialGlobalState(branches: Filial[]) {
      layout.actions.setBranchOptions(branches);

      if (branches.length) layout.actions.changeFilial(branches[0]);

      if (branches.length === 0)
        StyledSwal.Info(
          'Necessário adicionar uma filial no sistema',
          'Necessário completar cadastro',
          (opt) => opt.isConfirmed && navigate('/cadastros/filial/novo'),
          {
            confirmButtonText: 'Adicionar nova filial',
            allowOutsideClick: false
          }
        );
    }

    function redirectLogin(err?: any) {
      if (err) ApiErrorSwal(err, 'Erro ao buscar dados do usuário', 'Erro de usuário');

      navigate('/login');
    }

    return <WrappedComponent />;
  };
}

export default withAuth;
