import { SweetAlertOptions, SweetAlertResult } from "sweetalert2";
import { Swal } from "..";

/**
 * Renderiza um swal de error
 * 
 * @author Marcos Davi <marcos.davi@bubo.tech>
 * @param errorMsg Mensagem
 * @param errorTitle Título 
 */
function ErrorSwal(customOptions: SweetAlertOptions = {}, handleOption?: (option: SweetAlertResult<any>) => void) {
  Swal({
    showConfirmButton: true,
    title: customOptions.title || 'Erro',
    text: customOptions.text || 'Ocorreu um erro',
    ...customOptions,
    icon: 'error',
  }).then(opt => handleOption?.(opt));
}

export default ErrorSwal;