import { SweetAlertOptions, SweetAlertResult } from "sweetalert2";
import { Swal } from "..";

/**
 * Renderiza um swal de sucesso
 * 
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
function SuccessSwal(customOptions: SweetAlertOptions = {}, handleOption?: (option: SweetAlertResult<any>) => void) {
  Swal({
    showConfirmButton: true,
    title: customOptions.title || 'Sucesso',
    text: customOptions.text || 'Operação realizada com sucesso',
    ...customOptions,
    icon: 'success',
  }).then(opt => handleOption?.(opt));
}

export default SuccessSwal;