import ApiErrorSwal from '../apiErrorSwal/apierrorswal';
import ErrorSwal from './error-swal';
import InfoSwal from './info-swal';
import SuccessSwal from './success';
import Swal from './swal';
import LoadingSwal from '../loadingswal/loading-swal';


const Loading = () => LoadingSwal({ text: 'Carregando' });

const StyledSwal = {
  root: Swal,
  Info: InfoSwal,
  Error: ErrorSwal,
  ApiError: ApiErrorSwal,
  Success: SuccessSwal,
  Loading,
};

export default StyledSwal;
